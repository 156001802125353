<template lang="pug">
a(
  v-if="isExternal"
  :class="classes"
  :target="button.openInNewTab ? '_blank' : '_self'"
  :rel="button.openInNewTab ? 'noopener noreferrer' : ''"
  :href="button.to"
  @click="trackGtagEvent()"
) {{ button.label }}
|
NuxtLink(
  v-else
  :class="classes"
  :target="button.openInNewTab ? '_blank' : '_self'"
  :rel="button.openInNewTab ? 'noopener noreferrer' : ''"
  :to="button.to"
  @click="trackGtagEvent()"
) {{ button.label }}
</template>
  
<script setup lang="ts">
import { computed } from 'vue';
import type { TButton } from "@/models/button";

interface IProps {
  button?: TButton | null;
  large?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  button: null,
  large: false,
});

const button = computed(() => props.button || {
  label: "Button",
  to: "/contact",
  openInNewTab: false,
  outline: false,
  theme: "primary",
});

const classes = computed(() => ({
  "btn": true,
  [`btn-${button.value.theme || 'primary'}`]: true,
  "btn-outline": button.value.outline,
  "btn-large": props.large,
}));

const isExternal = computed(() => button.value?.to?.startsWith("http") || false);

const { gtag } = useGtag();

const trackGtagEvent = () => {
  if (!gtag) return;

  gtag('event', 'button_click', {
    event_category: 'Button',
    event_button_label: button.value.label || "",
    event_url: window.location.pathname || "",
    event_destination: button.value.to || "",
    value: 1,
  });
};
</script>